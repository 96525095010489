import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import RightArrow from "../images/svg/right-arrow.svg";
import imageUrl from "../helpers/flotiqImage"
const SuccessStoryTile = ({project}) => {
    return (
        <AniLink duration={.5} fade to={`/portfolio/${project.slug}`} className="success-story-tile">
            <img className="success-story-tile__photo" alt={project.name} src={imageUrl(project.projectTileImage, {width: 500, height: 250})} />
            <div className="story-content-intro">
                {/*<div className="success-story-tile__tag-cloud">*/}
                {/*    {project.tags && project.tags.map(tag => */}
                {/*        <AniLink duration={.5} fade key={tag.slug} className="story-tag" to={`/tag/${tag.slug}`}>{tag.name}</AniLink>*/}
                {/*    )}*/}
                {/*</div>*/}
                    <h4 className="success-story-tile__title">{project.name}</h4>
                <div className="success-story-tile__description" dangerouslySetInnerHTML={{__html: project.shortDescription}}></div>
                <div className="success-story-tile__read-more">
                    <p>read more</p>
                    <RightArrow/>
                </div>
            </div>
        </AniLink>
    )
}

export default SuccessStoryTile