function imageUrl(image, desiredSize) {
    if(Array.isArray(image) && image.length)
        image = image[0]
    
    if(!image || !image.id || !image.extension) {
        return ""
    }
        
    if(!desiredSize)
        desiredSize = {}
    let {width=0, height=0} = desiredSize;
    const mediaPrefix = "https://api.flotiq.com";
    return `${mediaPrefix}/image/${width}x${height}/${image.id}.${image.extension}`
}

export default imageUrl;