import React from "react"
import WBlogo from "../images/clients-logotypes/WB.svg"
import Vericonlogo from "../images/clients-logotypes/verizon.svg"
import CAlogo from "../images/clients-logotypes/CA.svg"
import Unileverlogo from "../images/clients-logotypes/Unilever.svg"
import DDBlogo from "../images/clients-logotypes/DBB_Tribal.svg"
import Rauxalogo from "../images/clients-logotypes/Rauxa.svg"
import Techlandlogo from "../images/clients-logotypes/techland.svg"
import Tescologo from "../images/clients-logotypes/Tesco.svg"

const ClientsLogotypes = () => {
    return (
        <div className="clients-logotypes">
            <div className="logotype">
                <WBlogo/>
            </div>
            <div className="logotype">
                <Vericonlogo/>
            </div>
            <div className="logotype">
                <CAlogo/>
            </div>
            <div className="logotype">
                <Unileverlogo/>
            </div>
            <div className="logotype">
                <DDBlogo/>
            </div>
            <div className="logotype">
                <Rauxalogo/>
            </div>
            <div className="logotype">
                <Tescologo/>
            </div>
            <div className="logotype">
                <Techlandlogo/>
            </div>
        </div>
    )
}

export default ClientsLogotypes
