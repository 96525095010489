import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default class TestimonialsSlider extends Component {
    render() {
        let {testimonials} = this.props;
        var settings = Object.assign({
            dots: true,
            dotsClass: 'slick-dots slider-dots',
            arrows: true,
            speed: 1000,
            slidesToScroll: 1,
            className: "center",
            centerMode: true,
            infinite: true,
            centerPadding: "10px",
            slidesToShow: 3,
            adaptiveHeight: true,

            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        centerPadding: "0",
                    }
                },
            ]
        }, this.props.settings || {});
        return (
            <section className="cw-container-fluid">
                <Slider {...settings} className="slider">
                    {
                        testimonials.map(testimonial => 
                            <div key={testimonial.id} className="testimonial-slide">
                                <blockquote dangerouslySetInnerHTML={{__html: testimonial.quote}}></blockquote>
                                <h3 className="testimonial-person-name">{testimonial.author}</h3>
                                <p className="testimonial-person-position">{testimonial.authorTitle}</p>
                            </div>
                        )
                    }
                </Slider>
            </section>
        );
    }
}