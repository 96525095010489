import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const PartnersLogotypes = () => {
    const data = useStaticQuery(graphql`
        query {
            partner1: file(relativePath: { eq: "partners-logotypes/microsoft-partner-network.png" }) {
                childImageSharp {
                    fluid(maxWidth: 700, traceSVG: {color: "#35bcd8"}) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            },
            partner2: file(relativePath: { eq: "partners-logotypes/linux-sysadmin-certified.png" }) {
                childImageSharp {
                    fluid(maxWidth: 700, traceSVG: {color: "#35bcd8"}) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            },
            partner3: file(relativePath: { eq: "partners-logotypes/amazon-partner-network.png" }) {
                childImageSharp {
                    fluid(maxWidth: 700, traceSVG: {color: "#35bcd8"}) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            },
            partner4: file(relativePath: { eq: "partners-logotypes/adobe-community-partner.png" }) {
                childImageSharp {
                    fluid(maxWidth: 700, traceSVG: {color: "#35bcd8"}) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
        }
    `)

    return (
        <div className="partners-logotypes">
            <Img className="partner-logo" fluid={data.partner1.childImageSharp.fluid} />
            <Img className="partner-logo" fluid={data.partner2.childImageSharp.fluid} />
            <Img className="partner-logo" fluid={data.partner3.childImageSharp.fluid} />
            <Img className="partner-logo" fluid={data.partner4.childImageSharp.fluid} />
        </div>
    )
}

export default PartnersLogotypes
