import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import TestimonialsSlider from "../components/testimonials-slider"
import ClutchLogo from "../images/svg/clutch-logo.svg"

const TestimonialsSection = ({sliderSettings}) => {
    const data = useStaticQuery(graphql`
        query {
            sectionBG: file(relativePath: { eq: "backgrounds/cdwv-bg-3.svg" }) {
                publicURL
            }
            testimonials: allCodewaveTestimonial(filter: {isPublic: {eq: true}}) {
                nodes {
                    id
                    author
                    authorTitle
                    quote
                }
            }
        }
    `);

    let testimonialsSectionStyle = {
        background: `radial-gradient(ellipse at center, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 90%,#F9FCFC 100%), url(${data.sectionBG.publicURL})`
    }
    let testimonials = data.testimonials.nodes;
    return (
        <section className="cw-container-fluid" style={testimonialsSectionStyle}>
            <div className="testimonials-section content-section">
                <h2 className="section-header">Testimonials</h2>
                <TestimonialsSlider testimonials={testimonials} settings={sliderSettings}/>
                <a className="clutch-link" href="https://clutch.co/profile/codewave" target="_blank" rel="noopener noreferrer">
                    Read more on <ClutchLogo/>
                </a>
            </div>
        </section>
    )
}

export default TestimonialsSection